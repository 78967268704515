// Prepare Circular Routes......
const prepareCircular = '/ditf-circular-management/prepare-circular/'
export const prepareCircularList = prepareCircular + 'list'
export const getStallinfo = prepareCircular + 'get-stall-info'
export const prepareCircularStore = prepareCircular + 'store'
export const prepareCircularUpdate = prepareCircular + 'update'
export const prepareCircularToggle = prepareCircular + 'toggle-status'
export const userListByDesignationApi = 'user/users-by-designation-id'
export const prepareCircularForward = prepareCircular + 'forward'
export const prepareCircularForwardList = prepareCircular + 'forward-list'
export const prepareCircularApproveReturn = prepareCircular + 'approve-return'
export const prepareCircularForwardCommentsApi = prepareCircular + 'forward-comments-list'
export const prepareCircularApprovalList = prepareCircular + 'approval-list'
export const prepareCircularPublish = prepareCircular + 'publish'
export const prepareCircularReturn = prepareCircular + 'return'
export const prepareCircularDetails = prepareCircular + 'details'

// Email Get Send Routes......
export const prepareCircularMailList = prepareCircular + 'mail-list'
export const prepareCircularMailSend = prepareCircular + 'sending-mail'
